<template>
  <div class="content-tabs">
    <div
      v-for="(item, index) in items"
      :key="item.title + '-' + index"
      class="content-tabs__item"
      :class="{ 'is-selected': selected === item.title || selected === item.id, [item?.class as string]: item?.class }"
      @click="changeTab(item)"
    >
      {{ item.title }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ITabItems } from '~/types';

  const props = defineProps<{
    items: ITabItems[];
    defaultTab?: string;
  }>();

  const emits = defineEmits<{
    (event: 'changeTab', value: string): void;
  }>();

  const router = useRouter();
  const url = getLastURLSegment();
  const { localizePath } = useProjectMethods();

  const selected = ref('');

  const scrollTab = async () => {
    const container = document.querySelector('.content-tabs');
    const appMain = document.querySelector('.app-main');
    const paddingLeft = appMain ? parseFloat(window.getComputedStyle(appMain).paddingLeft) : 0;
    const selectedElement = container?.querySelector('.is-selected');

    if (selectedElement) {
      const elementLeft = selectedElement?.offsetLeft;
      const containerScrollLeft = container?.scrollLeft || 0;
      const scrollOffset = elementLeft - containerScrollLeft - paddingLeft;

      container?.scrollBy({
        left: scrollOffset,
        behavior: 'smooth',
      });
    }
  };

  const setActiveTab = () => {
    if (props.defaultTab) {
      selected.value = props.defaultTab;
    } else {
      const defaultTab = props.items.find((item: ITabItems) => url === item.path);
      selected.value = defaultTab?.title || props.items[0]?.title || '';
    }
  };

  const changeTab = (item: ITabItems): void => {
    selected.value = item.title;

    if (item.path) {
      router.push(localizePath(item.path));
    }

    if (item.id) {
      emits('changeTab', item.id);
    }
  };

  defineExpose({ changeTab });

  watchEffect(() => {
    setActiveTab();
  });

  watch(
    selected,
    async () => {
      await nextTick();
      setTimeout(() => {
        scrollTab();
      }, 200);
    },
    { flush: 'post' }
  );

  onMounted(async () => {
    await nextTick();
    setTimeout(() => {
      scrollTab();
    }, 200);
  });
</script>

<style src="~/assets/styles/components/tab/tabs.scss" lang="scss" />
